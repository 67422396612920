<template>
  <div class="col-md-12" v-if="Object.keys(room_booking.room_booking_time_slots).length !== 0">
    <h5>Time Slots</h5>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3" v-for="(room_booking_time_slot, index) in roomBookingTimeSlots" :key="index">
            <span>{{ room_booking_time_slot.time_slot_rule.time_slot }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'room_booking_time_slots',
  props: ['room_booking'],
  data() {
    return {}
  },
  computed: {
    roomBookingTimeSlots() {
      return this.sortTimeSlot(this.room_booking)
    },
  },
  methods: {
    sortTimeSlot(roomBooking) {
      return roomBooking.room_booking_time_slots.sort(function(a, b) {
        return a.time_slot_rule.time_order - b.time_slot_rule.time_order
      })
    },
  },
}
</script>

<style scoped>
.item:last-child .comma {
  display: none;
}
</style>
