<template>
  <div class="col-md-12" v-if="Object.keys(room_booking).length !== 0">
    <div class="card">
      <div class="card-body">
        <a-steps :current="current" size="small" status="wait">
          <a-step title="Submitted" :description="room_booking.room_booking_statuses.filter(e => e.status === 'ROOM_BOOKER_SUBMIT').length > 0 ? customDate(room_booking.room_booking_statuses.find(e => e.status === 'ROOM_BOOKER_SUBMIT').created_at) : ''" />
          <a-step title="Approved" v-if="room_booking.room_booking_status.includes('ADMIN_APPROVED') && !room_booking.room_booking_status.includes('ADMIN_REJECTED')"  :description="room_booking.room_booking_statuses.filter(e => e.status === 'ADMIN_APPROVED').length > 0 ? customDate(room_booking.room_booking_statuses.find(e => e.status === 'ADMIN_APPROVED').created_at) : ''" />
          <a-step class="ant-steps-item-error" title="Rejected" v-else-if="room_booking.room_booking_status.includes('ADMIN_REJECTED') && !room_booking.room_booking_status.includes('ADMIN_APPROVED')"  :description="room_booking.room_booking_statuses.filter(e => e.status === 'ADMIN_REJECTED').length > 0 ? customDate(room_booking.room_booking_statuses.find(e => e.status === 'ADMIN_REJECTED').created_at) : ''" >
            <a-icon slot="icon" type="close-circle" />
          </a-step>
          <a-step title="Pending" v-else></a-step>
        </a-steps>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'room_booking_header_status',
  props: ['current', 'room_booking'],
  data() {
    return {}
  },
  methods: {
    customDate(date) {
      return moment(date).format('LLL')
    },
  },
}
</script>

<style scoped>
.ant-steps-item-description {
  margin-top: -5px !important;
}
</style>
