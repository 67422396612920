<template>
  <div class="col-md-12" v-if="Object.keys(room_booking.room_booking_entertainments).length !== 0">
    <h5>Entertainments</h5>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <h5>Restaurant Name: {{ room_booking.restaurant_name }}</h5>
          </div>
          <div class="col-md-6">
            <a href="javascript: void(0);" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-update-room-booking-entertainment-modal @click="$bvModal.show('add-update-room-booking-entertainment-modal')">
              <i class="fe fe-edit"/> If you want to update entertainments
            </a>
          </div>
          <div class="table-responsive">
            <table class="table table-sm table-bordered text-center" id="room_booking_entertainment_list">
              <thead>
              <tr>
                <th>#</th>
                <th>Food Item Name</th>
                <th>Unit</th>
                <th>Qty</th>
                <th>Unit Price</th>
                <th>Total Price</th>
                <th>Remarks</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(room_booking_entertainment, index) in room_booking.room_booking_entertainments" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ room_booking_entertainment.food_item_name }}</td>
                <td>{{ room_booking_entertainment.unit }}</td>
                <td>{{ room_booking_entertainment.qty }}</td>
                <td>{{ room_booking_entertainment.unit_price }}</td>
                <td>{{ room_booking_entertainment.total_price }}</td>
                <td>{{ room_booking_entertainment.remarks || 'N/A' }}</td>
              </tr>
              </tbody>
            </table>
            </div>
        </div>
      </div>
    </div>
    <room-booking-entertainment-update :room_booking="room_booking" :room_booking_entertainments="room_booking.room_booking_entertainments"></room-booking-entertainment-update>
  </div>
</template>

<script>
import roomBookingEntertainmentUpdate from '@/views/roomBooking/admin/partial/room_booking_entertainment_update'

export default {
  name: 'room_booking_entertainments',
  components: { roomBookingEntertainmentUpdate },
  props: ['room_booking'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
.item:last-child .comma {
  display: none;
}
</style>
