<template>
  <div class="col-md-12" v-if="Object.keys(room_booking.room_booking_entertainments).length !== 0">
    <div class="mb-5">
      <b-modal id="add-update-room-booking-entertainment-modal" title="Entertainment Update" size="xl" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">
          <div class="row mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="restaurant_name" class="col-form-label col-form-label-sm">Restaurant Name </label>
              <input type="text" v-model="room_booking.restaurant_name" v-validate="'max:100|min:2'" id="restaurant_name" name="restaurant_name" class="form-control form-control-sm" placeholder="Enter restaurant name"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-2">
              <button class="btn btn-sm btn-primary pull-right" @click="addItemRow">
                <i class="fa fa-plus mr-1"></i>Add Food Items
              </button>
            </div>
          </div>
          <div class="table-responsive-lg">
            <table class="table table-sm table-bordered text-center">
              <thead>
              <tr>
                <th>Food Item</th>
                <th>Unit</th>
                <th>Qty</th>
                <th>Unit Price</th>
                <th>Total Price</th>
                <th>Remarks</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for='(room_booking_entertainment, index) in room_booking_entertainments' :key="index">
                <td>
                  <input type="text" v-model="room_booking_entertainment.food_item_name"   v-validate="{ required: room_booking.restaurant_name ? true : false, max: 100, min: 1 }" :name="'food_item_name_' + index" :id="'food_item_name_' + index" class="form-control-plaintext form-control-sm" :class="errors.has('food_item_name_'+index) ? 'custom-error-class' : ''" style="text-align: center;" placeholder="Enter food item name">
                </td>
                <td>
                  <input type="text" v-model="room_booking_entertainment.unit"  v-validate="{ required: room_booking_entertainment.food_item_name ? true : false, max: 50, min: 1 }" :name="'unit_' + index" :id="'unit_' + index"  class="form-control-plaintext form-control-sm" :class="errors.has('unit_'+index) ? 'custom-error-class' : ''" style="text-align: center;" placeholder="Enter item unit">
                </td>
                <td>
                  <input type="text" v-model="room_booking_entertainment.qty" v-validate="{  max: 6, min: 1, regex: /^[1-9][0-9]*$/, required: room_booking_entertainment.food_item_name ? true : false }"  :name="'qty_'+index" :id="'qty_' + index" class="form-control-plaintext form-control-sm" :class="errors.has('qty_'+index) ? 'custom-error-class' : ''"  style="text-align: center;" placeholder="Enter item qty">
                </td>
                <td>
                  <input type="text" v-model="room_booking_entertainment.unit_price" v-validate="{ required: room_booking_entertainment.food_item_name ? true : false, max: 6, regex: /^[1-9][0-9]*$/ }"  :name="'unit_price_' + index" :id="'unit_price_' + index" class="form-control-plaintext form-control-sm" :class="errors.has('unit_price_'+index) ? 'custom-error-class' : ''" style="text-align: center;" placeholder="Enter item unit price">
                </td>
                <td>
                  <input type="text" v-model="unitTotal[index]" v-validate="{ required: room_booking_entertainment.food_item_name ? true : false,max: 7, regex: /^[1-9][0-9]*$/  }" :name="'total_price_' + index" readonly disabled class="form-control-plaintext form-control-sm" :class="errors.has('total_price_'+index) ? 'custom-error-class' : ''" style="text-align: center;" :id="'total_price_'+room_booking_entertainment.id">
                </td>
                <td>
                  <input type="text" v-model="room_booking_entertainment.remarks"  name="remarks" id="remarks" class="form-control-plaintext form-control-sm" placeholder="Enter remarks" style="text-align: center;">
                </td>
                <td>
                  <button class="btn btn-sm btn-danger" @click="deleteItemRow(index)" >
                    <i class="fa fa-remove mr-1"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="update">Update</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('add-update-room-booking-entertainment-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'

export default {
  name: 'room_booking_entertainment_update',
  props: ['room_booking', 'room_booking_entertainments'],
  data() {
    return {
      validation_errors: {},
      show: false,
      loader: false,
    }
  },
  computed: {
    unitTotal() {
      return this.room_booking_entertainments.map((roomBookingEntertainment) => {
        return (parseFloat(roomBookingEntertainment.qty || 0) * parseFloat(roomBookingEntertainment.unit_price || 0) || 0)
      })
    },
  },
  methods: {
    addItemRow: function () {
      this.room_booking_entertainments.push({
        food_item_name: '',
        qty: '',
        unit: '',
        unit_price: '',
        total_price: '',
        remarks: '',
      })
    },
    deleteItemRow(index) {
      this.room_booking_entertainments.splice(index, 1)
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/admin/room-booking/entertainment/update/' + this.room_booking.id, { room_booking_entertainments: this.room_booking_entertainments, room_booking: this.room_booking })
            .then(response => {
              if (!response.data.error) {
                this.loader = false
                this.$notification.success({
                  message: response.data.message,
                })
                this.$root.$on('getRoomBooking', this.getRoomBooking)
                this.$bvModal.hide('add-update-room-booking-entertainment-modal')
              } else {
                this.loader = false
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style scoped>
.custom-error-class{
  border-bottom: 1px solid #dc3545 !important;
}
</style>
