<template>
  <div class="col-md-12" v-if="Object.keys(room_booking.room_booking_additional_facilities).length !== 0">
    <h5>Additional Facilities</h5>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-3" v-for="(room_booking_additional_facility, index) in room_booking.room_booking_additional_facilities" :key="index">
             <span>{{ index + 1 }}:  {{ room_booking_additional_facility.additional_facility.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'room_booking_additional_facilities',
  props: ['room_booking'],
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
.item:last-child .comma {
  display: none;
}
</style>
